import { buttonStyle } from './../Components/Header/HeaderStyle';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1D1E20',
      light: '#3E3F43',
      dark: '#0F0F10',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#0AF2D0',
      light: '#8eeddf',
      dark: '#099A85',
      contrastText: '#1D1E20',
    },
    action: {
      disabledBackground: '#099A85',
    },
  },
  typography: {
    fontFamily: `'Nunito Sans', sans-serif;`,
    fontWeightRegular: 800,
    h6: {
      '@media (max-width:480px)': {
        fontSize: '24px',
      },
    },
    body1: {
      '@media (max-width:480px)': {
        fontSize: '20px',
      },
    }
  },
  components: {
    MuiInput: {
      defaultProps: {
        sx: {
          color: 'white',
          borderColor: 'white',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          color: 'white',
        },
      },
    },
    MuiButton:{
      styleOverrides: {
        root:{
          fontWeight: 800,
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontWeight: 800,
        }
      }
    }
  },
});
