import {
  Box,
  Modal,
  TextField,
  MenuItem,
  Button,
  ImageList,
  ImageListItem,
  useMediaQuery,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { IApiDocument } from "../../Types/Document/api.document";
import { useFormik } from "formik";
import { initialValues } from "./initial";
import { IDocumentCreate } from "../../Types/Document/document.create";
import { IDocumentUpdate } from "../../Types/Document/document.update";
import { createDocumentSchema } from "./validation";
import {
  manageDocumentModalStyleDesktop,
  manageDocumentModalStyleMobile,
} from "./style";
import { Status } from "../../Types/status.enum";
import { toBase64 } from "../../Service/filereader";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useMutation } from "@tanstack/react-query";
import { createPhotos, deletePhoto } from "../../Service/axios/photo.api";
import { useEffect } from "react";

interface IManageDocumentModal {
  open: boolean;
  handleClose: (callback: () => void) => void;
  handleSubmit: (document: IDocumentCreate & IDocumentUpdate) => void;
  handleDeleteDocument: (id: number) => void;
  document?: IApiDocument;
  userId: number;
  refetch: () => void;
  isLoading: boolean;
}

export const ManageDocumentModal = ({
  open,
  handleClose,
  handleSubmit,
  document,
  userId,
  handleDeleteDocument,
  refetch,
  isLoading,
}: IManageDocumentModal) => {
  const { photos, ...documentWithoutPhotos } = { ...document };

  const createPhotoMutation = useMutation(createPhotos, {
    onSuccess: refetch,
  });

  const deletePhotoMutation = useMutation(deletePhoto, {
    onSuccess: refetch,
  });

  const handleAddPhotos = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.files && document) {
      const files = Array.from(target.files);

      Promise.all(files.map((file) => toBase64(file))).then((photos) =>
        createPhotoMutation.mutate({ documentId: document?.id, photos })
      );
    }
  };

  const handleDeletePhoto = (id: number) => {
    deletePhotoMutation.mutate(id);
  };

  const formik = useFormik<IDocumentCreate & IDocumentUpdate>({
    initialValues: {
      ...initialValues,
      ...documentWithoutPhotos,
      userId,
    },
    onSubmit: handleSubmit,
    validationSchema: createDocumentSchema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
  });

  const matches = useMediaQuery("(max-width:480px)");

  const style = matches
    ? manageDocumentModalStyleMobile
    : manageDocumentModalStyleDesktop;

  return (
    <Modal open={open} onClose={() => handleClose(() => formik.resetForm())}>
      <Box sx={style.container}>
        <form
          style={{ width: "100%" }}
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <Box sx={style.form}>
            <Box sx={style.column}>
              <TextField
                fullWidth
                id="status"
                name="status"
                variant="standard"
                label="Статус"
                value={formik.values.status}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.status)}
                autoComplete="off"
                color="secondary"
                select
                sx={style.field}
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
              >
                <MenuItem value={Status.inChina}>{Status.inChina}</MenuItem>
                <MenuItem value={Status.sent}>{Status.sent}</MenuItem>
                <MenuItem value={Status.inProgress}>
                  {Status.inProgress}
                </MenuItem>
                <MenuItem value={Status.paymentPending}>
                  {Status.paymentPending}
                </MenuItem>
                <MenuItem value={Status.completed}>{Status.completed}</MenuItem>
              </TextField>
              <TextField
                id="cargoId"
                name="cargoId"
                variant="standard"
                label="Номер вантажу"
                value={formik.values.cargoId}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.cargoId)}
                autoComplete="off"
                color="secondary"
                sx={style.field}
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
                fullWidth
              />
              <TextField
                id="cargoWeight"
                name="cargoWeight"
                variant="standard"
                label="Вага"
                value={formik.values.cargoWeight}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.cargoWeight)}
                autoComplete="off"
                color="secondary"
                sx={style.field}
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
                fullWidth
              />
              <TextField
                id="volume"
                name="volume"
                variant="standard"
                label="Об'єм"
                value={formik.values.volume}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.volume)}
                autoComplete="off"
                color="secondary"
                sx={style.field}
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
                fullWidth
              />
              <DatePicker
                slotProps={{
                  textField: {
                    inputProps: {
                      style: style.date,
                    },
                  },
                }}
                sx={{ ...style.field, fontSize: "24px" }}
                label="Отримання в Китаї"
                value={dayjs.unix(formik.values.recieveInChinaDate)}
                format="DD/MM/YYYY"
                onChange={(date) =>
                  formik.setFieldValue("recieveInChinaDate", dayjs(date).unix())
                }
              />
              <DatePicker
                slotProps={{
                  textField: {
                    inputProps: {
                      style: style.date,
                    },
                  },
                }}
                sx={style.field}
                label="Відправка"
                value={dayjs.unix(formik.values.sendDate)}
                format="DD/MM/YYYY"
                onChange={(date) =>
                  formik.setFieldValue("sendDate", dayjs(date).unix())
                }
              />
              <DatePicker
                slotProps={{
                  textField: {
                    inputProps: {
                      style: style.date,
                    },
                  },
                  actionBar: {
                    actions: ["clear"],
                  },
                }}
                sx={style.field}
                label="Отримання в Україні"
                value={
                  formik.values.recieveInUkraineDate
                    ? dayjs.unix(formik.values.recieveInUkraineDate)
                    : null
                }
                format="DD/MM/YYYY"
                onChange={(date) =>
                  formik.setFieldValue(
                    "recieveInUkraineDate",
                    dayjs(date).unix() || null
                  )
                }
              />
            </Box>
            <Box sx={style.column}>
              <TextField
                id="placeQuantity"
                name="placeQuantity"
                variant="standard"
                label="Кількість місць"
                value={formik.values.placeQuantity}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.placeQuantity)}
                autoComplete="off"
                color="secondary"
                sx={style.field}
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
                fullWidth
              />
              <TextField
                id="unitQuantity"
                name="unitQuantity"
                variant="standard"
                label="Кількість одиниць"
                value={formik.values.unitQuantity}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.unitQuantity)}
                autoComplete="off"
                color="secondary"
                sx={style.field}
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
                fullWidth
              />
              <TextField
                id="estimatedValue"
                name="estimatedValue"
                variant="standard"
                label="Оціночна вартість"
                value={formik.values.estimatedValue}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.estimatedValue)}
                autoComplete="off"
                color="secondary"
                sx={style.field}
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
                fullWidth
              />
              <TextField
                id="deliveryValue"
                name="deliveryValue"
                variant="standard"
                label="Вартість доставки"
                value={formik.values.deliveryValue}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.deliveryValue)}
                autoComplete="off"
                color="secondary"
                sx={style.field}
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
                fullWidth
              />
              <TextField
                id="insuranceValue"
                name="insuranceValue"
                variant="standard"
                label="Вартість страховки"
                value={formik.values.insuranceValue}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.insuranceValue)}
                autoComplete="off"
                color="secondary"
                sx={style.field}
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
                fullWidth
              />
              <TextField
                id="trackNumber"
                name="trackNumber"
                variant="standard"
                label="Трек номер по Китаю"
                value={formik.values.trackNumber}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.trackNumber)}
                autoComplete="off"
                color="secondary"
                sx={style.field}
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
                fullWidth
              />
              <TextField
                id="additionalValue"
                name="additionalValue"
                variant="standard"
                label="Додаткові витрати"
                value={formik.values.additionalValue}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.additionalValue)}
                autoComplete="off"
                color="secondary"
                sx={style.field}
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
                fullWidth
              />
              <TextField
                id="additionalComment"
                name="additionalComment"
                variant="standard"
                label="Комментарій"
                value={formik.values.additionalComment}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.additionalComment)}
                autoComplete="off"
                color="secondary"
                sx={style.field}
                inputProps={{ style: style.input }}
                InputLabelProps={{ style: style.label }}
                fullWidth
              />
            </Box>
          </Box>
          <Box sx={style.buttonsContaner}>
            <Button
              sx={style.button}
              color="secondary"
              variant="contained"
              type="submit"
              disabled={
                createPhotoMutation.isLoading ||
                isLoading ||
                deletePhotoMutation.isLoading
              }
            >
              {document ? "Оновити документ" : "Створити документ"}
            </Button>
            {document && (
              <Button
                sx={style.button}
                color="info"
                variant="contained"
                component={"label"}
                disabled={
                  createPhotoMutation.isLoading ||
                  isLoading ||
                  deletePhotoMutation.isLoading
                }
              >
                Додати фото
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleAddPhotos}
                />
              </Button>
            )}
            {document && (
              <Button
                sx={style.button}
                color="error"
                variant="contained"
                onClick={() => handleDeleteDocument(document.id)}
                disabled={
                  createPhotoMutation.isLoading ||
                  isLoading ||
                  deletePhotoMutation.isLoading
                }
              >
                Видалити документ
              </Button>
            )}
          </Box>
          {photos && (
            <ImageList cols={1}>
              {photos.map((photo) => (
                <ImageListItem key={photo.id}>
                  <img src={photo.photoInBase64} alt="Loading..." />
                  <ImageListItemBar
                    actionIcon={
                      <IconButton
                        color="error"
                        onClick={() => handleDeletePhoto(photo.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}
        </form>
      </Box>
    </Modal>
  );
};
